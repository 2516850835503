import React, { useEffect, useState } from 'react'

// import '../assets/css/login.css'

import loginIcon from '../assets/images/login/login.png'
import logoIcon from '../assets/images/login/logo.svg'
import fbIcon from '../assets/images/login/fb.svg'
import googleIcon from '../assets/images/login/google.svg'
import linkedinIcon from '../assets/images/login/linkedin.svg'
import indiaIcon from '../assets/images/login/india.png'
import OtpInput from 'react-otp-input';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import { GET_ALL_USER_ROLES } from "../components/graphql";
import { Link } from 'gatsby'
import { navigate } from "gatsby";
import {
    TextField,
} from "@material-ui/core"

import close_image from "../assets/images/icon-close.png";

import BounceIndicator from "react-activity/lib/Bounce"
import axios from 'axios'
import apiUrls from '../api/apiUrl'
import { message } from 'antd';
import 'antd/lib/message/style/index.css'




function Login() {

    const [value, setValue] = useState('')
    const [otp, setotp] = useState('');

    const allUserRoles = useQuery(GET_ALL_USER_ROLES);
    var rolesContent = allUserRoles.data ? allUserRoles.data.userRoles : [];
    const [isOtpSent, setisOtpSent] = useState(false);

    const [pageLoading, setpageLoading] = useState(false);
    const [phoneError, setphoneError] = useState('');




    const handleGoogle = async () => {
        navigate(`${process.env.DEV_BACKEND_URL}/connect/google`);
    };

    const handleFacebook = async () => {
        navigate(`${process.env.DEV_BACKEND_URL}/connect/facebook`);
    };

    const handleLinkedin = async () => {
        navigate(`${process.env.DEV_BACKEND_URL}/connect/linkedin`);
    };

    const sendOtp = async () => {
        setphoneError(value ? value.length < 10 ? 'Phone Number Is Invalid' : '' : 'Phone Number Required')
        if (phoneError) {
            return null
        }

        setpageLoading(true)

        try {
            var response = await axios.post(apiUrls.login, { phone: value })
            response?.data?.status ? message.success(response?.data?.message || 'Otp Sent Successfullty', 5) : message.error(response?.data?.message || 'Failed to Send Otp', 5)
            response?.data?.status && setisOtpSent(true)
        } catch (error) {
            console.log(error)
            message.error(response.data.message || 'Failed to Send Otp', 5)

        } finally {
            setpageLoading(false)
        }
    }

    const verifyOtp = async (otpTemp) => {
        setpageLoading(true)

        if (!value || !otpTemp) { return null }
        try {
            var response = await axios.post(apiUrls.verify, { phone: value, otp: otpTemp })
            if (response?.data?.messgae) {
                return message.error(response.data.messgae || 'Otp verification failed', 5)
            }
            if (response?.data?.status && response?.data?.jwt) {
                localStorage.setItem("token", response?.data?.jwt);
                localStorage.setItem("userId", response?.data?.user?.id);
                localStorage.setItem("userName", response?.data?.user?.username || ' ')
                localStorage.setItem("firstName", response?.data?.user?.firstName || '')
                localStorage.setItem("sessionSetupTime", new Date())
                console.log("---------------called from login page------------------")
                navigate("/user-profile/")
            }

        } catch (error) {
            console.log(error)
            message.error(response.data.messgae || 'Otp verification failed', 5)
        } finally {
            setpageLoading(false)

        }
    }


    const handleClose = () => {
        if(localStorage.getItem('lastRevistedPage')){
            if(localStorage.getItem('lastRevistedPage')==='/house/'){
              if(localStorage.getItem('lastRevistedPageID')){
                navigate(localStorage.getItem('lastRevistedPage')+localStorage.getItem('lastRevistedPageID'))
                localStorage.removeItem('lastRevistedPage')
                localStorage.removeItem('lastRevistedPageID')
              }
              else{
                navigate(localStorage.getItem('lastRevistedPage'))
                localStorage.removeItem('lastRevistedPage') 
              }
            }
            else{
              navigate(localStorage.getItem('lastRevistedPage'))
              localStorage.removeItem('lastRevistedPage')
            }
        }
    }

    const handleOTPNumber = e =>{
        setotp(e)
        if(e.length ===6){
        verifyOtp(e)
        }
    }

    return (

        <div class="row m-0">
            <div class="col-md-6 position-relative login-m-none" style={{ backgroundColor: '#1e69b5', height: '100vh' }}>
                <img class="login-img" src={loginIcon} />
            </div>
            <div class="col-md-6 position-relative text-center login-m-show">
                <div class="login-right" >
                    <img class="login-logo" src={logoIcon} />
                    <h7>Welcome to Dimension France</h7>
                    <div class="login-underline"></div>
                    <p style={{ color: "#1e69b5", margin: "20px" }}>LOG IN</p>
                    <p>Please enter your mobile number</p>

                    {!isOtpSent ?
                        <div class="form-group row m-0 ">
                            <div class="col-md-12 m-auto text-start fw-bold">
                                {/* <input type="text" class="form-control" /> */}
                                <PhoneInput
                                    country={'in'}
                                    enableSearch={true}
                                    value={value}
                                    onChange={phone => setValue(phone)}
                                    className="text-start"
                                    containerClass='w-100 text-start fw-bold'
                                    inputProps={{
                                        autoFocus: true
                                    }}
                                />
                                <div className="submit">
                                    <div className='talk'>
                                    {pageLoading ? 
                                    <button disabled onClick={() => sendOtp()} >  
                                    <BounceIndicator size={25} color={"#0055a4"} /> </button>
                                        : <button onClick={() => sendOtp()} >LOGIN </button>}
                                    </div>
                                </div>
                            </div>
                        </div> :
                        <div class="form-group row m-0">
                            <div class="col-md-6 offset-md-3">
                                {/* <input type="text" class="form-control" /> */}
                                {/* <TextField
                                    error={otp.length <= 0}
                                    className="w-100"
                                    required
                                    margin="dense"
                                    id="country"
                                    label="Enter OTP"
                                    type="text"
                                    value={otp}
                                    onChange={e => {
                                        setotp(e.target.value)
                                    }}
                                > */}
                                {/* </TextField> */}
                                <OtpInput
                                    value={otp}
                                    inputStyle={{
                                        width: '2rem',
                                        height: '2rem',
                                        fontSize: '1rem',
                                        borderRadius: 4,
                                        margin: '5px',
                                        border: '2px solid rgba(0,0,0,0.3)',
                                    }}
                                    onChange={e => {
                                        handleOTPNumber(e)
                                    }}
                                    numInputs={6}
                                    separator={<span>-</span>}
                                    shouldAutoFocus={true}
                                />
                                <div className="submit w-50">
                                    {pageLoading ? <div className='talk'><button onClick={() => verifyOtp(otp)}><BounceIndicator size={25} color={"#0055a4"} /> </button></div>
                                        : <>
                                        <div className="talk">
                                            <button onClick={() => verifyOtp(otp)}>Verify Otp </button>
                                        </div>
                                            <p class="login-link"> Don't Recive OTP Yet ? <span onClick={() => sendOtp()} style={{ cursor:"pointer",color: "#1e69b5" }}> Resent otp </span></p>

                                        </>}
                                </div>
                            </div>
                        </div>
                    }

                    <p class="login-link"> Don't Have an Account Yet ? <span style={{ color: "#1e69b5" }}> <Link to="/register" className='text-decoration-none'>REGISTER</Link></span></p>
                    <p class="login-link">Or Connect With</p>
                    <img class="login-icon" src={fbIcon} alt="" onClick={() => { handleFacebook() }} style={{ cursor: 'pointer' }} />
                    <img class="login-icon" src={googleIcon} alt="" onClick={() => { handleGoogle() }} style={{ cursor: 'pointer' }} />
                    <img class="login-icon" src={linkedinIcon} alt="" onClick={() => { handleLinkedin() }} style={{ cursor: 'pointer' }} />
                </div>
            </div>
            <div class="col-md-6 text-center login-page-close-icon-show">
                <img
                    className="closeicon"
                    onClick={() => {
                        handleClose();
                    }}
                    src={close_image}
                    alt=""
                    />
            </div>
        </div>)
}

export default Login
